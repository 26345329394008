import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import $ from "jquery"
import Layout from "../components/layout"
import { motion } from "framer-motion"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Footer from "../components/footer"
import Drawer from "../components/drawer"

import "../styles/shul.scss"

import shulLoopVideo from "../videos/51 - Shul Final Car Loop Sequence 25 fps.mp4"
import goodWoodFestival from "../videos/49 - GoodwoodFOS_VaziraniA.mp4"

import hamMenuIcon from "../images/ham_menu_icon.svg"
import hamMenuClose from "../images/ham_menu_close.svg"
import dropDownArrow from "../images/drop-down-icon.svg"

import vaziraniV from "../images/vazirani-v.png"
import vaziraniFullLogo from "../images/vazirani-full-logo-yellow.png"
import shulLogo from "../images/shul-logo-yellow.png"

import batteryPack from "../images/BATTERY PACK GOLD DARK.png"
import powertrainGold from "../images/POWERTRAIN GOLD DARK.png"
import lvSystemGold from "../images/LV SYSTEM GOLD DARK.png"
import chassisGold from "../images/CHASSIS GOLDDARK.png"
import aeroGold from "../images/AERO GOLDDARK.png"

import bodyIllustration from "../images/BODY_ILLUSTRATION_BATTERYsmaller.png"
import batteryTransparent from "../images/Fotor2\ TALLER\ \(1\)_editedtransparent.webp"
import batteryTransparentPlaceholder from "../images/Fotor2\ TALLER\ \(1\)_editedtransparent-placeholder.png"

import volvoGold from "../images/Volvo Transparent gold drk.png"
import yamahaGold from "../images/Logo_Yamaha_transparent gold drk.png"
import rrGold from "../images/Rolls-Royce-logo gold dark.png"
import jagGold from "../images/Jaguar_2012_logo_gold dark.png"
import lrGold from "../images/Land rover gold drk.png"

import ourCustomersMap from "../images/World-Map NEW.jpg"

//React image gallery plugin
import ImageGallery from 'react-image-gallery';

const images = [
    {
        original: 'https://i.ibb.co/Xxrf1m1/HOME-PAGE-GALLERY-CHUNKY.jpg',
    },
    {
        original: 'https://i.ibb.co/Q6kJFm5/HOME-PAGE-GALLERY-CHUNKY2.jpg',
    },
    {
        original: 'https://i.ibb.co/DWxmMkT/HOME-PAGE-GALLERY-CHUNKY3.jpg',
    },
    {
        original: 'https://i.ibb.co/JFGRwvW/HOME-PAGE-GALLERY-CHUNKYF34.jpg',
    },
];

// markup
class EngineeringPage extends React.Component {

    componentDidMount() {
        $("#services").hover(function () {
            $(".drop-down").toggleClass("active");
        });

        $("#services-mobile").click(function () {
            $(".drop-down").toggleClass("active");
        });
        $(".drawer-icon").click(function () {
            $(".drawer").toggleClass("active");
            $(".drawer-icon").toggleClass("close");
            $(".drawer-icon-close").toggleClass("close");
        });


    }

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Vazirani Automotive | Engineering</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                    <meta name="theme-color" content="#020121" />
                    <meta name="description" content="Empowering you to go Electric ASAP." />
                    <meta property="og:title" content="Engineering | Vazirani Automotive" />
                    <meta name="keywords" content="Car design studio, car design, car design consulting, electric car build, electric car, EV engineering, electric car engineering, electric vehicle components, ev body design, EV designer, EV design, EV powertrain, Electric engine, futuristic car designer, best car design company, best EV company, best ev design company, electric car battery maker, ev battery pack, electric car maker, electric vehicle design, electric vehicle design studio, electric car design, electric car manufacturing, electric car prototype, electric car prototyping, best car designer, best electric car, best electric car builder, car designer india, car design studio india, car design in india" />

                    <meta property="og:image" content="../images/gallery/DESIGN GALLERY2_Fotor2_Fotore.jpg" />

                    <style type="text/css">
                        {`
                    body{
                        background-color: #020121;
                    }
                `}
                    </style>
                </Helmet>
                <div>
                    <img class="drawer-icon" height="17px" src={hamMenuIcon}></img>
                    <img class="drawer-icon-close" height="17px" src={hamMenuClose}></img>
                    <Drawer></Drawer>

                    <div class="wrapper">
                        <div class="header" magic magicId="header">
                            <div class="shul-v-logo">
                                <Link to="/"><img src={vaziraniV}></img></Link>
                            </div>
                            <div class="nav" style={{ justifySelf: 'right' }}>
                                <Link fade to="/">Shul</Link>
                                <span class="shul-u">_</span>
                            </div>
                            {/* <div class="divider"></div> */}
                            <div class="nav">
                                <Link fade to="/ekonk">Ekonk</Link>
                                {/* <a id="services">Ekonk
                            <img class="drop-down-arrow" src={dropDownArrow}></img>
                        </a> */}
                                {/* <div class="drop-down">
                            <Link to="/design">Design</Link>
                            <div class="divider"></div>
                            <Link to="/engineering">Engineering</Link>
                        </div> */}
                            </div>
                            {/* <div class="divider"></div> */}
                            <div class="nav">
                                <Link fade to="/dico">Dico</Link>
                            </div>

                            <div class="shul-vazirani-logo">
                                <Link to="/"><img src={vaziraniFullLogo}></img></Link>                    </div>
                        </div>

                        <Layout>
                            <div class="eng-sec1">
                                <div class="eng-feature-icons">
                                    <div>
                                        <LazyLoadImage effect="blur" src={batteryPack} height="40px" />
                                        <p>Battery</p>
                                    </div>
                                    <div>
                                        <LazyLoadImage effect="blur" src={powertrainGold} height="25px" />
                                        <p>Powertrain</p>
                                    </div>
                                    <div>
                                        <LazyLoadImage effect="blur" src={lvSystemGold} height="35px" />
                                        <p>System</p>
                                    </div>
                                    <div class="push">
                                        <LazyLoadImage effect="blur" src={chassisGold} height="25px" />
                                        <p>Chassis</p>
                                    </div>
                                    <div class="push2">
                                        <LazyLoadImage effect="blur" src={aeroGold} height="25px" />
                                        <p>Aero</p>
                                    </div>
                                </div>

                                <div class="battery">
                                    <div class="battery-desc">
                                        <div>
                                            <h2>Battery</h2>
                                            <p>Empowering you to go Electric ASAP.<br></br>
                                                Designed to plug and play, our unique battery solution developed over the last 4 years, has resulted in a design that can extract the highest power from the cells whilst maintaining long range.</p>
                                        </div>
                                        <div>
                                            <LazyLoadImage effect="blur" placeholderSrc={bodyIllustration} src={bodyIllustration} />
                                        </div>
                                    </div>

                                    <div class="battery-details">
                                        <div class="battery-details-list">
                                            <div>
                                                <h3>
                                                    Optimized Immersion Cooling
                                                </h3>
                                                <p>
                                                    Our proprietary advanced thermal management system results in 1.7x cooling efficiency of the cells resulting in extremely high performance.
                                                </p>
                                            </div>
                                            <div>
                                                <h3>
                                                    Modular Design
                                                </h3>
                                                <p>
                                                    Our scalable lightweight construction allows for ultimate flexibility for any use case without compromising performance.
                                                </p>
                                            </div>
                                            <div>
                                                <h3>
                                                    Fast Charging
                                                </h3>
                                                <p>
                                                    High power Fast Charging capability without any compromise on cell life.
                                                </p>
                                            </div>
                                            <div>
                                                <h3>
                                                    Intelligent BMS
                                                </h3>
                                                <p>
                                                    Our battery management system comes equipped with advanced fail-safe protocols with remote diagnostics.
                                                </p>
                                            </div>
                                            <div>
                                                <h3>
                                                    Homologation
                                                </h3>
                                                <p>
                                                    Our pack design is durable and robust, designed to pass the most stringent safety standards from around the world.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="battery-details-pic">
                                            {/* <!-- <img src="../images/Fotor2 TALLER (1)_editedtransparent.png"> --> */}
                                            <LazyLoadImage effect="blur" placeholderSrc={batteryTransparentPlaceholder} src={batteryTransparent} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="our-team-experience">
                                <h2>Our team experience:</h2>
                                <div class="company-logos">
                                    <div>
                                        <img src={volvoGold} style={{ marginTop: '-2px' }}></img>
                                    </div>
                                    <div>
                                        <img src={yamahaGold}></img>
                                    </div>
                                    <div>
                                        <img src={rrGold} style={{ width: '130%', marginLeft: "-4px" }}></img>
                                    </div>
                                    <div>
                                        <img src={jagGold} style={{ width: '190%', marginLeft: "-15px" }}></img>
                                    </div>
                                    <div>
                                        <img src={lrGold} style={{ width: '170%', marginLeft: "-10px" }}></img>
                                    </div>
                                </div>
                            </div>

                            <div class="our-customers">
                                <h2>Our customers:</h2>
                                <img src={ourCustomersMap}></img>
                            </div>

                            <Footer></Footer>
                        </Layout>

                    </div>

                </div>
            </React.Fragment>
        )
    }
}

export default EngineeringPage

